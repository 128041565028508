var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        staticClass: "gx-table-responsive",
        attrs: { columns: _vm.columns, "data-source": _vm.invoices },
        scopedSlots: _vm._u([
          {
            key: "operations",
            fn: function (text, row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.showInvoice(row)
                        },
                      },
                    },
                    [_vm._v("\n        View\n      ")]
                  ),
                ],
                1
              )
            },
          },
          {
            key: "total",
            fn: function (text) {
              return _c("div", {}, [
                _vm._v("€" + _vm._s(_vm.formatMoney(text))),
              ])
            },
          },
          {
            key: "refund",
            fn: function (text, row) {
              return _c("div", {}, [
                row.refund_amount > 0
                  ? _c("p", [_vm._v("€" + _vm._s(row.refund_amount))])
                  : _c("p", [_vm._v("-")]),
              ])
            },
          },
          {
            key: "transaction",
            fn: function (text, row) {
              return _c(
                "div",
                {},
                [
                  row.status === "complete"
                    ? [
                        _c("a-tag", { attrs: { color: "green" } }, [
                          _vm._v("Paid"),
                        ]),
                      ]
                    : _vm._e(),
                  row.status === "refund"
                    ? [
                        _c("a-tag", { attrs: { color: "orange" } }, [
                          _vm._v("Refund"),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              )
            },
          },
        ]),
      }),
      _c(
        "a-modal",
        {
          attrs: { width: "400px" },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  key: "back",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("\n        Close\n      ")]
              ),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c("a-col", { attrs: { xs: 12, lg: 12 } }, [
                _c("h4", [_vm._v(_vm._s(_vm.selectedInvoice.club_name))]),
                _c("p", [_vm._v(_vm._s(_vm.selectedInvoice.address))]),
              ]),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { staticStyle: { "text-align": "left" }, attrs: { span: 24 } },
                [
                  _c("p", [
                    _c("strong", [_vm._v("Location:")]),
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.selectedInvoice.location) + " "),
                    ]),
                  ]),
                  _c("p", [
                    _c("strong", [_vm._v("Attendee:")]),
                    _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.selectedInvoice.first_name) +
                          "\n            " +
                          _vm._s(_vm.selectedInvoice.last_name)
                      ),
                    ]),
                  ]),
                  _c("p", [
                    _c("strong", [_vm._v("Subtotal:")]),
                    _c("span", [
                      _vm._v(
                        " €" +
                          _vm._s(_vm.formatMoney(_vm.selectedInvoice.total))
                      ),
                    ]),
                  ]),
                  _vm.selectedInvoice.refund_amount > 0
                    ? _c("p", [
                        _c("strong", [_vm._v("Refund Amount:")]),
                        _c("span", [
                          _vm._v(
                            " €" +
                              _vm._s(
                                _vm.formatMoney(
                                  _vm.selectedInvoice.refund_amount
                                )
                              )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.selectedInvoice.created_at
                    ? _c("p", [
                        _c("strong", [_vm._v("Created At:")]),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.nFormat(_vm.selectedInvoice.created_at)
                              )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.selectedInvoice.Transaction
                    ? _c("p", [
                        _c("strong", [_vm._v("Transaction ID:")]),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.selectedInvoice.Transaction.transaction_id
                              )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.selectedInvoice.Transaction &&
                  _vm.selectedInvoice.Transaction.id
                    ? _c("p", [
                        _c("strong", [_vm._v("Payment Received:")]),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.nTime(
                                  _vm.selectedInvoice.Transaction.created_at
                                )
                              )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.selectedInvoice.status === "complete"
                    ? [
                        _c(
                          "a-tag",
                          {
                            staticStyle: { "margin-right": "0" },
                            attrs: { color: "green" },
                          },
                          [_vm._v("Paid")]
                        ),
                      ]
                    : [
                        _c(
                          "a-tag",
                          {
                            staticStyle: { "margin-right": "0" },
                            attrs: { color: "orange" },
                          },
                          [_vm._v("Refund")]
                        ),
                      ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }